import { Button, Card, Text, Textarea, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { showErrorToast, showSuccessToast } from "../../utils/toasts";

const UsersForm = (props: any) => {
  const { user } = props;
  const [userForm, setUserForm] = useState<any>({});

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setUserForm(user);
    }
  }, [user]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUserForm({ ...userForm, [name]: value });
  };

  const updateUser = () => {
    api
      .put("/users/" + user.id, userForm)
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({ message: "Usuario actualizado correctamente" });
          navigate("/users");
        }
      })
      .catch((err) => {
        showErrorToast({ message: "Error al actualizar el usuario" });
      });
  };
  const createUser = () => {
    api
      .post("/users", userForm)
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({ message: "Usuario creado correctamente" });
          navigate("/users");
        }
      })
      .catch((err) => {
        showErrorToast({ message: "Error al crear el usuario" });
      });
  };
  return (
    <Card>
      <Card.Section p="md">
        <Text size={"md"}>{user ? "Editar usuario" : "Crear usuario"}</Text>
      </Card.Section>

      <TextInput
        name="name"
        placeholder="Nombre"
        label="Nombre"
        value={userForm.name}
        onChange={handleChange}
        mt="sm"
      />

      <TextInput
        name="email"
        type="email"
        placeholder="Correo electrónico"
        label="Correo electrónico"
        value={userForm.email}
        onChange={handleChange}
        mt="sm"
      />
      <TextInput
        name="password"
        type="password"
        placeholder="Contraseña"
        label="Contraseña"
        value={userForm.password}
        onChange={handleChange}
        error={
          userForm.password != userForm.password_confirmation &&
          "Las contraseñas no son iguales"
        }
        mt="sm"
      />
      <TextInput
        name="password_confirmation"
        type="password"
        placeholder="Repite la contraseña"
        label="Repite la contraseña"
        value={userForm.password_confirmation}
        onChange={handleChange}
        error={
          userForm.password != userForm.password_confirmation &&
          "Las contraseñas no son iguales"
        }
        mt="sm"
      />

      <Button fullWidth mt="sm" onClick={user ? updateUser : createUser}>
        {user ? "Actualizar usuario" : "Crear usuario"}
      </Button>
    </Card>
  );
};

export default UsersForm;
