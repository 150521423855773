import { Button, Card, Text, TextInput } from "@mantine/core";
import { IconPlus, IconSearch } from "@tabler/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import BaseTable from "../../components/BaseTable";
import SWRrender from "../../components/SWRrender";
import MainLayout from "../../layouts/MainLayout";
import styles from "./styles";
const columns = [
  {
    Header: "Nombre y Apellidos",
    accessor: "name",
  },
  {
    Header: "Correo electronico",
    accessor: "email",
  },
  {
    Header: "DNI",
    accessor: "document",
  },
  {
    Header: "Puestos",
    Cell: (row: any) => (
      <>
        {row.row.original.positions.map((position: any) => (
          <Text>{position.external_position_id}</Text>
        ))}
      </>
    ),
  },
];

const Volunteers = () => {
  return (
    <MainLayout>
      <SWRrender>
        <VolunteersList />
      </SWRrender>
    </MainLayout>
  );
};

const VolunteersList = () => {
  const {
    data: { volunteers },
  } = useSWR("/volunteers", { suspense: true });
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const handleRowClick = (row: any) => {
    navigate(`/volunteers/${row.id}`);
  };

  const { classes } = styles();
  return (
    <Card>
      <Card.Section p="md">
        <Text size={"md"}>Listado de voluntarios</Text>
      </Card.Section>
      <Card.Section p="md">
        <div className={classes.header}>
          <div>
            <Button onClick={() => navigate("/volunteers/new")}>
              <IconPlus />
            </Button>
          </div>

          <div>
            <TextInput
              width={"100%"}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Buscar voluntario"
              icon={<IconSearch />}
            />
          </div>
        </div>
      </Card.Section>
      <BaseTable
        data={volunteers}
        columns={columns}
        onClick={handleRowClick}
        search={search}
      />
    </Card>
  );
};

export default Volunteers;
