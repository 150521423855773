import { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Group,
  TextInput,
  Divider,
  MultiSelect,
} from "@mantine/core";
import api from "../../api";
import { showErrorToast, showSuccessToast } from "../../utils/toasts";
import useSWR, { mutate } from "swr";
import SWRrender from "../SWRrender";

function PositionModal(props: any) {
  const { opened, setOpened, position, setPosition, latlong, setLatlong } =
    props;

  const [localPosition, setLocalPosition] = useState<any>(null);

  useEffect(() => {
    if (!opened) {
      setPosition && setPosition(null);
      setLocalPosition(null);
      setVolunteersPositions([]);
      latlong && setLatlong(null);
    } else {
      if (position) {
        setLocalPosition(position);
        setVolunteersPositions([...position.volunteers.map((v: any) => v.id)]);
      }
      if (latlong) {
        setLocalPosition({ lat: latlong.lat, long: latlong.lng });
      }
    }
  }, [opened]);

  const handleChange = (e: any) => {
    setLocalPosition({ ...localPosition, [e.target.name]: e.target.value });
  };

  const updatePosition = () => {
    api
      .put("/positions/" + localPosition.id, localPosition)
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({
            message: "Posición actualizada correctamente",
          });
          mutate("/positions");
          setOpened(false);
        }
      })
      .catch((err) => {
        showErrorToast({
          message: "Error al actualizar la posición",
        });
      });
  };

  const assignVolunteers = () => {
    api
      .post("/positions/" + localPosition.id + "/volunteers", {
        volunteer_id: volunteersPositions,
      })
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({
            message: "Voluntarios asignados correctamente",
          });
          mutate("/positions");
          setOpened(false);
        }
      })
      .catch((err) => {
        showErrorToast({
          message: "Error al asignar los voluntarios",
        });
      });
  };

  const createPosition = () => {
    api
      .post("/positions", localPosition)
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({
            message: "Posición creada correctamente",
          });
          mutate("/positions");
          setOpened(false);
        }
      })
      .catch((err) => {
        showErrorToast({
          message: "Error al crear la posición",
        });
      });
  };

  const [volunteersPositions, setVolunteersPositions] = useState<any>([]);

  return (
    <>
      <Modal
        zIndex={10000}
        opened={opened}
        onClose={() => setOpened(false)}
        title={
          latlong
            ? "Crear nuevo puesto"
            : "Puesto " + (position?.external_position_id || "")
        }
      >
        {!latlong && (
          <>
            <SWRrender>
              <Volunteers
                volunteersPositions={volunteersPositions}
                setVolunteersPositions={setVolunteersPositions}
              />
            </SWRrender>
            <Button fullWidth mt={"sm"} onClick={assignVolunteers}>
              Asignar voluntarios
            </Button>
            <Divider mt={"md"} />
          </>
        )}
        <TextInput
          name="Zona"
          label="Zona"
          mt={"sm"}
          value={position?.zone}
          onChange={handleChange}
        />{" "}
        <TextInput
          name="place"
          label="Lugar"
          value={position?.place}
          mt={"sm"}
          onChange={handleChange}
        />{" "}
        <TextInput
          name="external_position_id"
          label="ID Policia"
          defaultValue={position?.external_position_id}
          onChange={handleChange}
          mt={"sm"}
        />{" "}
        <TextInput
          name="volunteers_needed"
          label="Voluntarios requeridos"
          defaultValue={position?.volunteers_needed}
          onChange={handleChange}
          mt={"sm"}
        />{" "}
        <TextInput
          name="traffic_anotations"
          label="Observaciones"
          value={position?.traffic_anotations}
          onChange={handleChange}
          mt={"sm"}
        />{" "}
        <Button
          fullWidth
          mt="sm"
          onClick={latlong ? createPosition : updatePosition}
        >
          {latlong ? "Crear puesto" : "Actualizar puesto"}
        </Button>
      </Modal>
    </>
  );
}

function Volunteers(props: any) {
  const {
    data: { volunteers },
  } = useSWR("/volunteers", { suspense: true });

  const { volunteersPositions, setVolunteersPositions } = props;
  return (
    <MultiSelect
      data={volunteers.map((volunteer: any) => ({
        value: volunteer.id,
        label: volunteer.name + " (" + volunteer.positions.length + ")",
      }))}
      label="Voluntarios asignados"
      value={volunteersPositions}
      onChange={(e: any) => setVolunteersPositions(e)}
      searchable
      placeholder="No hay voluntarios asignados"
    />
  );
}

export default PositionModal;
