import { Button, Card, Text } from "@mantine/core";
import { IconPlus } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import BaseTable from "../../components/BaseTable";
import SWRrender from "../../components/SWRrender";
import MainLayout from "../../layouts/MainLayout";

const columns = [
  {
    Header: "Nombre",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "email",
  },
];

const Users = () => {
  return (
    <MainLayout>
      <SWRrender>
        <UsersTable />
      </SWRrender>
    </MainLayout>
  );
};

const UsersTable = () => {
  const {
    data: { users },
  } = useSWR("/users", { suspense: true });
  const navigate = useNavigate();
  return (
    <Card>
      <Card.Section p="md">
        <Text size={"md"}>Listado de usuarios</Text>
      </Card.Section>
      <Card.Section pl="md" pb="md">
        <div>
          <Button onClick={() => navigate("/users/new")}>
            <IconPlus />
          </Button>
        </div>
      </Card.Section>
      <BaseTable data={users} columns={columns} />
    </Card>
  );
};

export default Users;
