import axios from "axios";
import { getAccessToken } from "../utils/token";

const api = axios.create({
  baseURL: "https://vuelta22-api.threetech.es/api",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + getAccessToken(),
  },
});

api.interceptors.request.use(
  (config: any) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default api;
