import { Button, Card, Text, Textarea, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { showErrorToast, showSuccessToast } from "../../utils/toasts";

const VolunteersForm = (props: any) => {
  const { volunteer } = props;
  const [volunteerForm, setVolunteerForm] = useState<any>({});

  const navigate = useNavigate();

  useEffect(() => {
    if (volunteer) {
      setVolunteerForm(volunteer);
    }
  }, [volunteer]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setVolunteerForm({ ...volunteerForm, [name]: value });
  };

  const updateVolunteer = () => {
    api
      .put("/volunteers/" + volunteer.id, volunteerForm)
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({ message: "Voluntario actualizado correctamente" });
          navigate("/volunteers");
        }
      })
      .catch((err) => {
        showErrorToast({ message: "Error al actualizar el voluntario" });
      });
  };
  const createVolunteer = () => {
    api
      .post("/volunteers", volunteerForm)
      .then((res) => {
        if (res.data.ok) {
          showSuccessToast({ message: "Voluntario creado correctamente" });
          navigate("/volunteers");
        }
      })
      .catch((err) => {
        showErrorToast({ message: "Error al crear el voluntario" });
      });
  };
  return (
    <Card>
      <Card.Section p="md">
        <Text size={"md"}>
          {volunteer ? "Editar voluntario" : "Crear voluntario"}
        </Text>
      </Card.Section>
      <TextInput
        name="name"
        placeholder="Nombre y apellidos"
        label="Nombre y apellidos"
        value={volunteerForm.name}
        onChange={handleChange}
      />
      <TextInput
        name="document"
        placeholder="Documento"
        label="Documento"
        value={volunteerForm.document}
        onChange={handleChange}
        mt="sm"
      />

      <TextInput
        name="email"
        type="email"
        placeholder="Correo electrónico"
        label="Correo electrónico"
        value={volunteerForm.email}
        onChange={handleChange}
        mt="sm"
      />

      <Textarea
        name="observations"
        label="Observaciones"
        placeholder="Observaciones"
        value={volunteerForm.observations}
        onChange={handleChange}
        mt="sm"
      />

      <Button
        fullWidth
        mt="sm"
        onClick={volunteer ? updateVolunteer : createVolunteer}
      >
        {volunteer ? "Actualizar voluntario" : "Crear voluntario"}
      </Button>
    </Card>
  );
};

export default VolunteersForm;
