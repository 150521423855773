import UsersForm from "../../components/UsersForm";
import MainLayout from "../../layouts/MainLayout";

const UsersCreate = () => {
  return (
    <MainLayout>
      <UsersForm />
    </MainLayout>
  );
};

export default UsersCreate;
