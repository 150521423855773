import { Box, Card, Text } from "@mantine/core";
import useSWR from "swr";
import BaseTable from "../../components/BaseTable";
import SWRrender from "../../components/SWRrender";
import MainLayout from "../../layouts/MainLayout";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { iconGreen, iconRed } from "../../utils/mapIcons";
import PositionModal from "../../components/PositionModal";
import { useState } from "react";

const columns = [
  {
    Header: "ID Policia",
    accessor: "external_position_id",
  },
  {
    Header: "Zona / Lugar",
    Cell: (row: any) => (
      <Text>
        {console.log(row)}
        {row.row.original?.zone && row.row.original?.place && (
          <>
            {row.row.original?.zone} / {row.row.original?.place}
          </>
        )}
      </Text>
    ),
  },
  {
    Header: "Voluntarios requeridos",
    accessor: "volunteers_needed",
  },
];

const Positions = () => {
  return (
    <MainLayout>
      <SWRrender>
        <PositionsList />
      </SWRrender>
    </MainLayout>
  );
};

const PositionsList = () => {
  const {
    data: { positions },
  } = useSWR("/positions", { suspense: true });

  const [openModal, setOpenModal] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);

  return (
    <>
      <Box style={{ height: "50%" }}>
        <MapContainer
          center={[38.267922, -0.689126]}
          zoom={13}
          style={{ zIndex: 0 }}
          scrollWheelZoom={true}
        >
          <NewPosition />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          />
          {positions?.map((position: any) => (
            <Marker
              position={[position.lat, position.long]}
              icon={
                position.volunteers_needed <= position.volunteers.length
                  ? iconGreen
                  : iconRed
              }
              eventHandlers={{
                click: (e) => {
                  setOpenModal(true);
                  setSelectedPosition(position);
                },
              }}
            >
              <Popup>
                <Text>
                  {position.zone && position?.place && (
                    <>
                      {position?.zone} / {position?.place}
                    </>
                  )}
                </Text>
                {position.external_position_id}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </Box>
      <Card>
        <Card.Section p="md">
          <Text size={"md"}>Listado de puestos</Text>
        </Card.Section>
        <BaseTable data={positions} columns={columns} />
      </Card>
      <PositionModal
        opened={openModal}
        setOpened={setOpenModal}
        position={selectedPosition}
        setPosition={setSelectedPosition}
      />
    </>
  );
};

function NewPosition() {
  const [openModal, setOpenModal] = useState(false);
  const [latlong, setLatlong] = useState<any>(null);
  const map = useMapEvents({
    click(e) {
      setLatlong(e.latlng);
      setOpenModal(true);
    },
  });
  return (
    <PositionModal
      opened={openModal}
      setOpened={setOpenModal}
      latlong={latlong}
      setLatlong={setLatlong}
    />
  );
}
export default Positions;
