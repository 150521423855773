import VolunteersForm from "../../components/VolunteersForm";
import MainLayout from "../../layouts/MainLayout";

const VolunteersCreate = () => {
  return (
    <MainLayout>
      <VolunteersForm />
    </MainLayout>
  );
};

export default VolunteersCreate;
