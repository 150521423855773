import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Container,
  Button,
} from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import api from "../../api";
import { showErrorToast } from "../../utils/toasts";
import { saveToken } from "../../utils/token";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleSubmit = () => {
    api
      .post("/login", { email, password })
      .then((data) => {
        console.log(data);
        if (data.data.token) {
          saveToken(data.data.token);
          mutate("/user", null, true);
          navigate("/");
        }
      })
      .catch((err) => {
        showErrorToast({
          message: "Correo electronico o contraseña incorrectos",
        });
      });
  };

  return (
    <div
      style={{
        minHeight: "98vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container size={"sm"} my={40} style={{ width: "100%" }}>
        <Title
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
          })}
        >
          Iniciar sesión
        </Title>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <TextInput
            label="Correo electronico"
            placeholder="test@example.com"
            required
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <PasswordInput
            label="Contraseña"
            placeholder="********"
            required
            name="password"
            mt="md"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Button fullWidth mt="xl" onClick={() => handleSubmit()}>
            Iniciar sesión
          </Button>
        </Paper>
      </Container>
    </div>
  );
};

export default LoginPage;
