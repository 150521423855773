import { useParams } from "react-router-dom";
import useSWR from "swr";
import SWRrender from "../../components/SWRrender";
import VolunteersForm from "../../components/VolunteersForm";
import MainLayout from "../../layouts/MainLayout";

const VolunteersUpdate = () => {
  return (
    <MainLayout>
      <SWRrender
        redirectOnError="/volunteers"
        toastMessage="No se ha podido encontrar el voluntario indicado"
      >
        <RenderForm />
      </SWRrender>
    </MainLayout>
  );
};

const RenderForm = () => {
  const { id } = useParams();
  const {
    data: { volunteer },
  } = useSWR(`/volunteers/${id}`, { suspense: true });
  return <VolunteersForm volunteer={volunteer} />;
};

export default VolunteersUpdate;
