import L from "leaflet";

const iconRed = new L.Icon({
  iconUrl: "/icon-red.png",
  iconRetinaUrl: "/icon-red.png",
  iconSize: new L.Point(32, 45),
});

const iconGreen = new L.Icon({
  iconUrl: "/icon-green.png",
  iconRetinaUrl: "/icon-green.png",
  iconSize: new L.Point(32, 45),
});

export { iconRed, iconGreen };
